import React, { useState, useRef, useEffect } from 'react';
import {
    Stage,
    SubscribeType,
    StageEvents,
    ConnectionState,
    StreamType,
} from 'amazon-ivs-web-broadcast';
import { IVSRealTimeClient, CreateParticipantTokenCommand } from "@aws-sdk/client-ivs-realtime";
import { useAccount } from '../../store/account';

const BroadcastComponent = ({ arn }) => {
    const accountStore = useAccount();
    const [connected, setConnected] = useState(false);
    const [joining, setJoining] = useState(false);

    const videoRef = useRef(null);

    let stage = null;


    useEffect(() => {
        async function generateToken() {
            const ivsRealtimeClient = new IVSRealTimeClient({
                region: 'us-east-1', credentials: {
                    accessKeyId: accountStore?.config?.ak,
                    secretAccessKey: accountStore?.config?.sk,
                }
            });
            const stageArn = arn;
            const createStageTokenRequest = new CreateParticipantTokenCommand({
                stageArn,
            });
            const response = await ivsRealtimeClient.send(createStageTokenRequest);

            if (response.participantToken?.token) {
                joinStage(response.participantToken?.token)
            }
        }

        if (arn && accountStore?.config?.ak && accountStore?.config?.sk) {
            generateToken();
        }

        return () => {
            leaveStage();
        };
    }, [accountStore?.config]);

    const joinStage = async (token) => {
        if (connected || joining) return;

        setJoining(true);

        if (!token) {
            alert('Atención, no se pudo generar el token de conexión');
            setJoining(false);
            return;
        }

        try {
            const strategy = {
                stageStreamsToPublish: () => [], // No publicamos ningún stream (ni cámara ni micrófono)
                shouldPublishParticipant: () => false, // No publicamos nada
                shouldSubscribeToParticipant: () => SubscribeType.AUDIO_VIDEO, // Nos suscribimos solo a audio y video
            };

            stage = new Stage(token, strategy);

            stage.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
                setConnected(state === ConnectionState.CONNECTED);
                setJoining(false);
            });

            stage.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, (participant, streams) => {
                const videoStreams = streams.filter((stream) => stream.streamType === StreamType.VIDEO);
                const audioStreams = streams.filter((stream) => stream.streamType === StreamType.AUDIO);

                const videoElement = videoRef.current;

                // Verificamos si el videoElement está disponible antes de agregar las pistas
                if (videoElement) {
                    // Combinamos las pistas de video y audio en un solo MediaStream
                    const mediaStreamTracks = [];

                    videoStreams.forEach((stream) => {
                        if (stream.mediaStreamTrack) {
                            mediaStreamTracks.push(stream.mediaStreamTrack);
                        }
                    });

                    audioStreams.forEach((stream) => {
                        if (stream.mediaStreamTrack) {
                            mediaStreamTracks.push(stream.mediaStreamTrack);
                        }
                    });

                    // Verificamos si el videoElement tiene un srcObject, de no ser así, creamos uno
                    if (videoElement.srcObject) {
                        mediaStreamTracks.forEach((track) => {
                            videoElement.srcObject.addTrack(track);
                        });
                    } else {
                        videoElement.srcObject = new MediaStream(mediaStreamTracks);
                    }
                }
            });

            await stage.join();
        } catch (error) {
            console.error('Error joining stage:', error.message);
            setJoining(false);
            setConnected(false);
        }
    };

    const leaveStage = () => {
        if (stage) stage.leave();

        setJoining(false);
        setConnected(false);
    };

    return (
        <video
            ref={videoRef}
            autoPlay
            playsInline
            controls
            style={{ width: '100%' }}
        />
    );
};


export default BroadcastComponent;

