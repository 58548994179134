import React, { Component, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";

import HomePage from "./pages/home/index";
import AuctionDetails from "./pages/auctions/AuctionDetails";
import LotDetails from "./pages/lots/LotDetails";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import FAQ from "./pages/dynamics/FAQ";
import Register from "./pages/register/Register";
import { connect } from "react-redux";
import { userIsLoggedAction } from "./redux/actions/UserActions";
import MyAccount from "./pages/account/MyAccount";
import { Constants } from "./Constants";
import WayToPay from "./pages/dynamics/WayToPay";
import Buy from "./pages/dynamics/Buy";
import Sell from "./pages/dynamics/Sell";
import { configAccountAction } from "./redux/actions/AccountActions";
import Loading from "./components/loading/Loading";
import TermAndConditions from "./pages/dynamics/TermAndConditions";
import GlobalContainer from "./GlobalContainer";
import AuctionInPersonDetails from "./pages/auctions_in_person/AuctionDetails";
import AboutUs from "./pages/dynamics/AboutUs";
import SellBuy from "./pages/dynamics/SellBuy";

class App extends Component {
	constructor(props) {
		super(props);
		this.props.userIsLogged();
		this.props.getAccountConfig();
		this.updateStyleVariables();
	}

	updateStyleVariables = () => {
		try {
			let root = document.documentElement;
			//
			if (window.style_config?.primary_background_color) {
				root.style.setProperty(
					"--primary-background-color",
					window.style_config.primary_background_color
				);
			}
			if (window.style_config?.secondary_background_color) {
				root.style.setProperty(
					"--secondary-background-color",
					window.style_config.secondary_background_color
				);
			}
			if (window.style_config?.text_color_with_primary) {
				root.style.setProperty(
					"--text-color-with-primary",
					window.style_config.text_color_with_primary
				);
			}
			if (window.style_config?.text_color_with_secondary) {
				root.style.setProperty(
					"--text-color-with-secondary",
					window.style_config.text_color_with_secondary
				);
			}
			if (window.style_config?.button_offer_winning) {
				root.style.setProperty(
					"--button-offer-winning",
					window.style_config.button_offer_winning
				);
			}
			if (window.style_config?.button_offer_not_offered) {
				root.style.setProperty(
					"--button-offer-not-offered",
					window.style_config.button_offer_not_offered
				);
			}
			if (window.style_config?.button_offer_not_auctioned) {
				root.style.setProperty(
					"--button-offer-not_auctioned",
					window.style_config.button_offer_not_auctioned
				);
			}
			if (window.style_config?.button_offer_auctioned) {
				root.style.setProperty(
					"--button-offer-auctioned",
					window.style_config.button_offer_auctioned
				);
			}
			if (window.style_config?.secondary_background_accordion_hover) {
				root.style.setProperty(
					"--secondary-background-color-accordion-hover",
					window.style_config.secondary_background_accordion_hover
				);
			}
			if (window.style_config?.intercalate_background_color) {
				root.style.setProperty(
					"--intercalate-background-color",
					window.style_config.intercalate_background_color
				);
			}
			// 
			root.style.setProperty(
				"--footer-background",
				window.style_config?.footer_background || window.style_config?.secondary_background_color || ''
			);
			root.style.setProperty(
				"--footer-text-color",
				window.style_config?.footer_text_color || window.style_config?.text_color_with_secondary || ''
			);
			root.style.setProperty(
				"--code_access_background",
				window.style_config.code_access_background || '#fff'
			);
			root.style.setProperty(
				"--code_access_text",
				window.style_config.code_access_text || '#000'
			);
			//
		} catch (error) { }
	};

	createMeta = (content, name, property) => {
		let meta = document.createElement("meta");
		if (name !== null) {
			meta.name = name;
		}
		if (property !== null) {
			meta.property = property;
		}
		meta.content = content;
		document.getElementsByTagName("head")[0].appendChild(meta);
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.configAccount.config !== this.props.configAccount.config) {
			if (this.props.configAccount?.config?.title_tabs) {
				document.title = this.props.configAccount.config.title_tabs
				this.createMeta(
					this.props.configAccount.config.title_tabs,
					"description",
					null
				);
				this.createMeta(
					this.props.configAccount.config.title_tabs,
					"og:title",
					"og:title"
				);
				this.createMeta(
					this.props.configAccount.config.title_tabs,
					"twitter:card",
					null
				);
				this.createMeta(
					this.props.configAccount.config.title_tabs,
					null,
					"og:image"
				);
			}
			if (this.props.configAccount?.config?.footer_company_web) {
				let link = document.createElement("link");
				link.rel = "canonical";
				link.href = this.props.configAccount.config.footer_company_web;
				document.getElementsByTagName("head")[0].appendChild(link);
			}
			if (this.props.configAccount?.config?.analytic_code && this.props.configAccount?.config?.analytic_code !== null) {
				const script = document.createElement("script");
				script.src = "https://www.googletagmanager.com/gtag/js?id=G-TZ3N6HXFW0";
				script.async = true;
				document.body.appendChild(script);
				window.dataLayer = window.dataLayer || [];
				function gtag() {
					window.dataLayer.push(arguments);
				}
				gtag("js", new Date());
				if (window.gcode) {
					gtag("config", window.gcode);
				}
				gtag("config", this.props.configAccount.config.analytic_code);
			}
			if (this.props.configAccount?.config?.favicon_web) {
				let link = document.createElement("link");
				link.rel = "icon";
				link.href =
					Constants.BASE_URL + this.props.configAccount.config.favicon_web;
				document.getElementsByTagName("head")[0].appendChild(link);
			}
		}
	};

	render() {
		return (
			<React.Fragment>
				{this.props.configAccount.loading && <Loading />}
				<BrowserRouter>
					<GlobalContainer>
						<Switch>
							<Route exact path="/subastas/:id/:y" component={AuctionDetails} />
							<Route exact path="/subastas/:id" component={AuctionDetails} />
							<Route exact path="/subastas-presenciales/:id" component={AuctionInPersonDetails} />
							<Route exact path="/lotes/:id/:y" component={LotDetails} />
							<Route exact path="/lotes/:id" component={LotDetails} />
							<Route exact path="/contacto" component={Contact} />
							{/* DYNAMIC PAGES */}
							{this.props.configAccount.config?.include_page_faq && (
								<Route exact path="/ayuda" component={FAQ} />
							)}
							{this.props.configAccount.config?.include_page_buy && (
								<Route exact path="/comprar" component={Buy} />
							)}
							{this.props.configAccount.config?.include_page_sell && (
								<Route exact path="/vender" component={Sell} />
							)}
							{this.props.configAccount.config?.include_about_us && (
								<Route exact path="/nosotros" component={AboutUs} />
							)}
							{this.props.configAccount.config?.include_page_sell_buy && (
								<Route exact path="/comprar-vender" component={SellBuy} />
							)}
							{this.props.configAccount.config?.include_page_form_pay && (
								<Route exact path="/forma-de-pago" component={WayToPay} />
							)}
							{this.props.configAccount.config?.include_page_conditions && (
								<Route
									exact
									path="/terminos-condiciones"
									component={TermAndConditions}
								/>
							)}

							{this.props.sessionProps.account === null && (
								<Route exact path="/ingresar" component={Login} />
							)}
							{this.props.sessionProps.account === null && (
								<Route
									exact
									path="/ingresar/:auction/:y/:segment"
									component={Login}
								/>
							)}
							{this.props.sessionProps.account === null && (
								<Route exact path="/registrar" component={Register} />
							)}
							{this.props.sessionProps.account !== null && (
								<Route
									exact
									path="/mi-cuenta/:tab/:auction/:page"
									component={MyAccount}
								/>
							)}
							{this.props.sessionProps.account !== null && (
								<Route exact path="/mi-cuenta/:tab" component={MyAccount} />
							)}

							<Route exact path="/" component={HomePage} />
							<Route path="*" render={() => <RedirectToHome />} />
						</Switch>
					</GlobalContainer>
				</BrowserRouter>
			</React.Fragment>
		);
	}
}

const RedirectToHome = () => {
	let location = useLocation();
	let history = useHistory();

	useEffect(() => {
		// Si la ruta no es exactamente "/", redirige a la página de inicio
		if (location.pathname !== "/") {
			history.push("/");
		}
	}, [location, history]);

	return null; // O renderiza algo si es necesario durante la redirección
};

const mapStateToProps = (state) => ({
	sessionProps: state.userReducer,
	configAccount: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
	userIsLogged: () => {
		dispatch(userIsLoggedAction());
	},
	getAccountConfig: () => {
		dispatch(configAccountAction());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
